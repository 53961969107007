<div class="container-fluid">
  <div class="row flex-nowrap">
    <main class="col p-0">
      <nav id="top-nav" class="navbar border-bottom" style="padding-top: 5px; padding-bottom: 5px;">
        <div class="bg-pattern"></div>
        <div class="container-fluid">
          <ng-container *ngIf="!toggleOffCanvas">
            <div class="text-white">
              <img src="/assets/images/nhso.png" alt="Logo" height="35" class=" ">
              <h6 class="mx-3 d-inline">ระบบบริหารนิติกรรมสัญญา Smart-Contract</h6>
            </div>
          </ng-container>
          <ng-container *ngIf="toggleOffCanvas">
            <ng-template #content let-offcanvas>
              <div class="offcanvas-header">
                <h6 class="offcanvas-title" id="offcanvas-basic-title">เมนู</h6>
                <button type="button" class="btn-close" aria-label="Close"
                        (click)="offcanvas.dismiss('Cross click')"></button>
              </div>
              <div class="offcanvas-body">
                <ng-container [ngTemplateOutlet]="sidebar"></ng-container>
              </div>
            </ng-template>
            <button (click)="open(content)" [attr.aria-expanded]="!sidebarCollapsed" class="navbar-toggler border-white"
                    type="button" data-bs-toggle="collapse"
                    data-bs-target="#sidebar" aria-controls="sidebar" aria-expanded="false"
                    aria-label="Toggle navigation">
              <i class="bi bi-layout-sidebar text-white fs-3"></i>
            </button>
          </ng-container>
          <div class="d-flex">
            <button (click)="gotoEportal()" type="button" class="btn btn-sm btn-default border-0">
              <i class="bi bi-box-arrow-right me-2"></i>ไปยัง e-Portal
            </button>

<!--            todo-->

<!--            <div class="mx-3">-->
<!--              <ul class="navbar-nav">-->
<!--                <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-end">-->
<!--                  <a class="nav-link text-white" tabindex="0" ngbDropdownToggle id="themeChange" role="button">-->
<!--                    <i class="bi bi-circle-half me-2 text-white"></i>-->
<!--                  </a>-->
<!--                  <div ngbDropdownMenu aria-labelledby="themeChange" class="dropdown-menu">-->
<!--                    <a ngbDropdownItem role="button" (click)="setThemeToDefault()">ค่าเริ่มต้น</a>-->
<!--                    <a ngbDropdownItem role="button" (click)="setThemeToDefault()">ตัวเลือก 1</a>-->
<!--                    <a ngbDropdownItem role="button" (click)="setThemeToCustom2()">ตัวเลือก 2</a>-->
<!--                  </div>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->

            <div class="d-flex align-items-center">
              <div class="vr d-none d-lg-flex h-100 mx-lg-2 text-white"></div>
              <div class="flex-grow-1 ms-3 text-truncate text-end" style="min-width: 150px;">
                <div class="align-items-center">
                  <a role="button" class="btn btn-sm text-white" id="dropdownMenu"
                     style="text-align: start; padding: 0;">
                    {{ user?.name }}
                  </a>
                </div>
                <div *ngIf="!(user?.organization?.orgType === 'PERSONAL')" class="text-white"
                     style="font-size: 0.75rem;">
                  <!--ชื่อหน่วยงานที่มาจาก DC, OSS-->
                  {{ (user?.organization?.orgType === 'COMPANY' && user?.source === 'OSS') ? 'บริษัท' : '' }} {{ user?.organization?.name }}
                </div>
              </div>
            </div>
            <div ngbDropdown class="flex-shrink-0 mx-3" display="dynamic" container="body">
              <a role="button" ngbDropdownToggle class="text-white">
                <i class="bi-person-circle fs-4 text-white"></i>
              </a>
              <div ngbDropdownMenu aria-labelledby="dropdownMenu">
                <button ngbDropdownItem (click)="userInfo()">
                  <i class="bi bi-person me-3"></i>บัญชีผู้ใช้งาน
                </button>
                <button ngbDropdownItem (click)="logout()">
                  <i class="bi bi-door-open me-3"></i>ออกจากระบบ
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <nav *hasAllAuthority="['smart_contract']" id="top-nav2"
           class="navbar navbar-expand-lg bg-white overflow-hidden">
        <div class="container-fluid">
          <div class="collapse navbar-collapse" id="navbar">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a routerLink="/landing" routerLinkActive="active" class="nav-link text-truncate maxw-250px"
                   data-bs-parent="#sidebar">
                  <img src="assets/icons/home.png" alt="" class="me-2" height="15">
                  <span>หน้าแรก</span>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="/dashboard" routerLinkActive="active" class="nav-link text-truncate maxw-250px"
                   data-bs-parent="#sidebar">
                  <img src="assets/icons/pie-chart.png" alt="" class="me-2" height="15">
                  <span>Dashboard</span>
                </a>
              </li>
              <li *hasAnyAuthority="['DEPT_ZONE','ZONE_PM']; except:['ZONE_DIRECTOR'] "
                  class="nav-item">
                <a routerLink="/contract/forms" routerLinkActive="active" class="nav-link text-truncate maxw-250px"
                   data-bs-parent="#sidebar">
                  <img src="assets/icons/folder.png" alt="" class="me-2" height="15">
                  <span>สร้างนิติกรรมสัญญา</span>
                </a>
              </li>
              <li *hasAnyAuthority="['DEPT_ZONE','ZONE_PM']; except:['ZONE_DIRECTOR']" class="nav-item">
                <a routerLink="/contract/draft-forms" routerLinkActive="active"
                   class="nav-link text-truncate maxw-250px"
                   data-bs-parent="#sidebar">
                  <img src="assets/icons/folder.png" alt="" class="me-2" height="15">
                  <span>นิติกรรมสัญญาที่บันทึกร่างไว้</span>
                </a>
              </li>
              <li *hasAnyAuthority="['DEPT_ZONE','ZONE_PM']; except:['ZONE_DIRECTOR']" class="nav-item">
                <a routerLink="/contract/list" routerLinkActive="active" class="nav-link text-truncate maxw-250px"
                   data-bs-parent="#sidebar">
                  <img src="assets/icons/folder.png" alt="" class="me-2" height="15">
                  <span>นิติกรรมสัญญาที่ส่งเรื่องแล้ว</span>
                </a>
              </li>
              <li *hasAnyAuthority="['DEPT_REGISTRATION']" class="nav-item">
                <a routerLink="/contract/reg-office/tracking" routerLinkActive="active"
                   class="nav-link text-truncate" data-bs-parent="#sidebar">
                  <img src="assets/icons/check.png" alt="" class="me-2" height="15">
                  <span>ติดตามสถานะและตรวจสอบนิติกรรมสัญญา [สำนักทะเบียน]</span>
                </a>
              </li>
              <li *hasAnyAuthority="['DEPT_REGISTRATION']" class="nav-item">
                <a routerLink="/contract/vendor/list" routerLinkActive="active"
                   class="nav-link text-truncate" data-bs-parent="#sidebar">
                  <img src="assets/icons/check.png" alt="" class="me-2" height="15">
                  <span>ตรวจสอบรหัสคู่สัญญาที่เพิ่มใหม่</span>
                </a>
              </li>
              <li *hasAnyAuthority="['DEPT_LAW']" class="nav-item">
                <a routerLink="/contract/law-office/tracking" routerLinkActive="active"
                   class="nav-link text-truncate" data-bs-parent="#sidebar">
                  <img src="assets/icons/check.png" alt="" class="me-2" height="15">
                  <span>ติดตามสถานะและตรวจสอบนิติกรรมสัญญา [สำนักกฎหมาย]</span>
                </a>
              </li>
              <li *hasAnyAuthority="['DEPT_FUND']" class="nav-item">
                <a routerLink="/contract/fund-office/tracking" routerLinkActive="active"
                   class="nav-link text-truncate" data-bs-parent="#sidebar">
                  <img src="assets/icons/check.png" alt="" class="me-2" height="15">
                  <span>ติดตามสถานะและตรวจสอบนิติกรรมสัญญา [สบก.]</span>
                </a>
              </li>
              <li *hasAnyAuthority="['ZONE_DIRECTOR', 'NHSO_EXECUTIVE']" class="nav-item">
                <a routerLink="/contract/consideration" routerLinkActive="active"
                   class="nav-link text-truncate" data-bs-parent="#sidebar">
                  <img src="assets/icons/bulb.png" alt="" class="me-2" height="15">
                  <span>พิจารณานิติกรรมสัญญา</span>
                </a>
              </li>
              <li *hasAnyAuthority="['DEPT_REGISTRATION']" class="nav-item">
                <a routerLink="/master-data" routerLinkActive="active"
                   class="nav-link text-truncate" data-bs-parent="#sidebar">
                  <img src="assets/icons/database.png" alt="" class="me-2" height="15">
                  <span>จัดการข้อมูล master</span>
                </a>
              </li>
              <li *hasAnyAuthority="['DEPT_REGISTRATION','DEPT_REGISTRATION', 'DEPT_LAW', 'DEPT_FUND']" class="nav-item">
                <a routerLink="/reports" routerLinkActive="active"
                   class="nav-link text-truncate" data-bs-parent="#sidebar">
                  <img src="assets/icons/barchart.png" alt="" class="me-2" height="15">
                  <span>รายงาน</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div id="main-content" class="container-fluid pt-2">
        <app-version></app-version>
        <div id="header-wrapper-fluid" class="d-none">
          <app-header></app-header>
        </div>
        <div id="header-wrapper" class="">
          <div class="row justify-content-center">
            <div class="offset-xl-1 col-xl-10 bg-white">
              <app-header></app-header>
            </div>
            <div class="col"></div>
          </div>
        </div>
        <div class="mt-2">
          <app-network-unstable></app-network-unstable>
          <app-loading></app-loading>
          <router-outlet></router-outlet>
        </div>
      </div>
    </main>
  </div>
</div>
<ng-template #sidebar>
  <ng-container>
    <a routerLink="/landing" routerLinkActive="active" class="list-group-item border-0 d-inline-block text-truncate"
       data-bs-parent="#sidebar">
      <i class="bi bi-house"></i> <span>หน้าแรก</span>
    </a>
    <a routerLink="/dashboard" routerLinkActive="active" class="list-group-item border-0 d-inline-block text-truncate"
       data-bs-parent="#sidebar">
      <i class="bi bi-window"></i> <span>Dashboard</span>
    </a>
  </ng-container>
</ng-template>
