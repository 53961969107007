import {inject, Injectable} from "@angular/core";
import {Authority} from "../../generated-model/model";
import {filter} from "rxjs/operators";
import {environment} from "../../environments/environment";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {map, Observable} from "rxjs";
import {UserDataService} from "../shared/services/user-data.service";

@Injectable({providedIn: "root"})
export class SecurityService {

  private userDataService = inject(UserDataService);

  constructor(public oidc: OidcSecurityService) {
  }

  public hasAnyAuthority(checkAuthorities: Authority[]): Observable<boolean> {
    return this.oidc.userData$
      .pipe(filter(e => e?.userData),
        map(u => {
          let userAuthorities: Authority[] = u.userData?.resource_access?.[environment.clientId]?.roles || [];
          let realmRole: Authority[] = u.userData?.realm_access?.roles || [];
          userAuthorities = [...userAuthorities, ...realmRole];
          if (this.hasAny(checkAuthorities, userAuthorities)) {
            return true
          } else {
            return false
          }
        }))
  }

  public hasAnyAuthorityExcept(checkAuthorities: Authority[], except: Authority[]): Observable<boolean> {
    return this.oidc.userData$
      .pipe(
        filter(e => e?.userData),
        map(u => {
          let userAuthorities: Authority[] = u.userData?.resource_access?.[environment.clientId]?.roles || [];
          let realmRole: Authority[] = u.userData?.realm_access?.roles || [];
          userAuthorities = [...userAuthorities, ...realmRole];
          return this.hasAnyExcept(checkAuthorities, userAuthorities, except);
        }),
      );
  }

  private hasAny(authorityList: Authority[], authorities: string[] = []): boolean {
    if (authorities) {
      let hasAuthority = authorities.some(e => authorityList.some(ex => e == ex));
      return hasAuthority;
    } else {
      return false;
    }
  }

  private hasAnyExcept(authorityList: Authority[], authorities: string[] = [], except: Authority[]): boolean {
    if (authorities) {
      if (except?.length) {
        let notContainsExcludeList = !authorities.some(e => except.some(ex => e == ex));
        let hasAnyAuthority = authorities.some(e => authorityList.some(ex => e == ex));
        return hasAnyAuthority && notContainsExcludeList;
      }
      return authorities.some(e => authorityList.some(ex => e == ex));
    } else {
      return false;
    }
  }
}
