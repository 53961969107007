import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import {ChangeDetectorRef, Directive, forwardRef, inject, Input, OnChanges, SimpleChanges} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {VatType} from "../../../generated-model/model";

@Directive({
  selector: '[vatIdValid][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => VatIdValid),
      multi: true
    }
  ]
})
export class VatIdValid implements Validator, OnChanges {
  @Input({alias: 'vatType', required: true}) vatType: VatType;
  private http = inject(HttpClient);

  constructor(private cd: ChangeDetectorRef) {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.vatType) {
      if (!(this.vatType == "EXEMPTION")) {
        if (!control.value) {
          return {required: true};
        }
        if (control.value.length < 13 || control.value.length > 18) {
          return {pattern: true};
        }
      }
    }
    return undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['vatType']?.currentValue) {

    }
  }
}
