import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {NavigationStart, Router} from "@angular/router";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {OidcSecurityService} from "angular-auth-oidc-client";

@Injectable()
export class Http401Interceptor implements HttpInterceptor {
  private currentUrl: string;
  private active = true;

  constructor(private router: Router, public oidcSecurityService: OidcSecurityService,) {

    this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.currentUrl = e.url
      }
    })
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(event => {
    }, err => {
      this.hadleError(err);
    }));
  }

  private hadleError(err: HttpErrorResponse) {
    if (err.status == 401) {
      this.oidcSecurityService
        .logoffAndRevokeTokens()
        .subscribe(value => {
          //do nothing
        });
    }
  }
}
