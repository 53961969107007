import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from "@angular/forms";
import {catchError, debounceTime, Observable, of} from "rxjs";
import {ChangeDetectorRef, Directive, forwardRef, inject, Input} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {IgnoreNullHttpParams} from "../commons/Ignore-null-http-params";
import {map, tap} from "rxjs/operators";
import {VendorSource} from "../../../generated-model/model";

@Directive({
  selector: '[vendorIdValid][ngModel]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => VendorIdValid),
      multi: true
    }
  ]
})
export class VendorIdValid implements AsyncValidator {

  @Input({alias: 'source', required: true}) source: VendorSource;
  private http = inject(HttpClient);

  constructor(private cd: ChangeDetectorRef) {
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (control.value) {
      if (!control) {
        return of({required: true});
      }
      return this.http.get(
        `${environment.serverUrl}/api/vendor/active/validate-vendor-id`,
        {
          params: IgnoreNullHttpParams.fromObject({vendorId: control.value, source: this.source}).toHttpParam(),
          responseType: "text"
        },
      ).pipe(
        debounceTime(300),
        map(vendorId => {
          if (vendorId) {
            return {vendorIdExists: true}; //
          } else {
            return null;
          }
        }),
        tap(value => console.log('tap ', value)),
        catchError(() => null)
      );
    }
    return null
  }
}
