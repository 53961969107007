import {Component} from '@angular/core';

@Component({
  templateUrl: '403.component.html',
  styleUrls: ['./403.component.scss'],
  standalone: true
})
export class P403Component {

  constructor() {
  }
}
