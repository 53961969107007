import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpContext} from "@angular/common/http";
import {Observable, share, single} from "rxjs";
import {UserData} from "../../../generated-model/model";
import {environment} from "../../../environments/environment";
import {IGNORE_LOADING} from "../commons/loader/loading-interceptor";
import {IGNORE_400_ERROR} from "../commons/interceptor/http400-interceptor";
import {IGNORE_5XX_ERROR} from "../commons/interceptor/http5xx-interceptor";

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  public loggedIn = false;
  private http = inject(HttpClient);
  public userData$;

  constructor() {
    const httpContext = new HttpContext();
    httpContext.set(IGNORE_LOADING, true)
    httpContext.set(IGNORE_400_ERROR, true);
    httpContext.set(IGNORE_5XX_ERROR, true);
    this.userData$ = this.http.get<UserData>(`${environment.serverUrl}/public/api/user-data`, {context: httpContext})
      .pipe(
        single(e => !!e),
        share()
      );
  }

  getCurrent(): Observable<UserData> {
    return this.userData$;
  }
}
