<ng-container *ngIf="size == 'normal'">
  <input [id]="id"
         class="form-control d-none"
         [attr.name]="name"
         type="file"
         ng2FileSelect
         #file
         [accept]="getAcceptType()"
         [uploader]="uploader"
         (change)="upload($event)">
  <ng-container *ngIf="!isUploading()"
                class="small file-upload-label {{disabled?'disabled':''}} text-truncate vertical-align-middle"
                style="max-width: 250px;">
    <label [for]="!disabled? id: ''"
           role="button"
           (click)="file.click()"
           class="btn btn-default bg-cornsilk border me-2">
      <i class="bi bi-upload me-2"></i>
      <span *ngIf="!value">เลือกไฟล์...</span>
      <span *ngIf="value" class="small text-truncate vertical-align-middle" style="max-width: 150px;">
      {{ value?.originalFileName }}
    </span>
    </label>
  </ng-container>
  <ng-container *ngIf="isUploading()">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    กำลังอัพโหลด...
  </ng-container>
  <span *ngIf="value" (click)="preview()" class="d-inline-block preview-button align-middle text-muted text-truncate"
        style="max-width: 150px;">
    <span class="ms-2">ดู</span>
  </span>
  <span *ngIf="!value" class="d-inline-block align-middle text-muted text-truncate" style="max-width: 150px;">ยังไม่ได้เลือก</span>
</ng-container>

<ng-container *ngIf="size == 'sm-inline'">
  <input [id]="id"
         class="form-control d-none"
         [attr.name]="name"
         type="file"
         ng2FileSelect
         #filesm
         [accept]="getAcceptType()"
         [uploader]="uploader"
         (change)="upload($event)">
  <ng-container *ngIf="!isUploading()"
                class="small file-upload-label {{disabled?'disabled':''}} text-truncate vertical-align-middle"
                style="max-width: 250px;">
    <div *ngIf="!disabled" class="uploaded upload-wrapper">
      <i class="file-icon bi bi-cloud-upload"></i>
      <div class="file text-truncate" style="max-width: 220px; cursor: pointer;">
        <div class="file__name ">
          <a *ngIf="!value?.originalFileName" (click)="filesm.click()">
            อัปโหลดไฟล์...
          </a>
          <a *ngIf="value?.originalFileName" role="button" (click)="preview()">
            {{ value?.originalFileName }}
          </a>
        </div>
      </div>
      <i *ngIf="value?.originalFileName && !disabled" (click)="removeFile()" class="fas fa-times"></i>
    </div>
    <div *ngIf="disabled  && value?.originalFileName" class="uploaded upload-wrapper">
      <i class="file-icon bi bi-link-45deg"></i>
      <div class="file text-truncate" style="max-width: 220px; cursor: pointer;">
        <div class="file__name ">
          <a role="button" (click)="preview()"
             class="preview-button text-truncate" style="max-width: 220px; text-decoration: none;">
            {{ value?.originalFileName }}
          </a>
        </div>
      </div>
    </div>
    <div *ngIf="disabled && !value?.originalFileName">
      <a role="button"
         class="preview-button no-file-selected text-truncate text-muted"
         style="max-width: 220px; text-decoration: none;">
        --ไม่มีไฟล์แนบ--
      </a>
    </div>
    <!--    <div class="file-select">-->
    <!--      <div *ngIf="!disabled" role="button" class="text-truncate" style="max-width: 220px;" (click)="filesm.click()">-->
    <!--        <i class="bi bi-upload me-1"></i>-->
    <!--        {{ value?.originalFileName || 'เลือกไฟล์...' }}-->
    <!--      </div>-->
    <!--      <a *ngIf="disabled && value?.originalFileName" role="button" (click)="preview()"-->
    <!--         class="preview-button text-truncate" style="max-width: 220px;">-->
    <!--        <i class="bi bi-paperclip me-1"></i>-->
    <!--        {{ value?.originalFileName }}-->
    <!--      </a>-->
    <!--      <a *ngIf="disabled && !value?.originalFileName" role="button"-->
    <!--         class="preview-button no-file-selected text-truncate text-muted" style="max-width: 220px;">-->
    <!--        <i>&#45;&#45;ไม่มีไฟล์แนบ&#45;&#45;</i>-->
    <!--      </a>-->
    <!--      <ng-container *ngIf="value?.originalFileName && !disabled">-->
    <!--        <span (click)="preview()" class="bi bi-eye remove-file me-2"></span>-->
    <!--        <span *ngIf="!disabled" (click)="removeFile()" class="bi bi-trash remove-file"></span>-->
    <!--      </ng-container>-->
    <!--    </div>-->
  </ng-container>
  <ng-container *ngIf="isUploading()">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    กำลังอัพโหลด...
  </ng-container>
</ng-container>
<custom-error [error]="validates[0]" *ngIf="validates?.length">{{ validates[0] }}</custom-error>
