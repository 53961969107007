import {Component, forwardRef, inject, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-select-budget-year',
  templateUrl: './select-budget-year.component.html',
  styleUrls: ['./select-budget-year.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectBudgetYearComponent)
    }
  ]
})
export class SelectBudgetYearComponent implements OnInit{

  budgetYear: number[] = []
  value: number

  http = inject(HttpClient)

  ngOnInit(): void {
    this.searchBudgetYear()
  }
  searchBudgetYear() {
    this.http.get<number[]>(`api/budget-year`).subscribe(data => {
      this.budgetYear = data
    })
  }
  valueChange(value: number) {
    this.onChange(value);
    this.onTouched();
  }
  onChange = (value) => {
  };
  onTouched = () => {
  };
  writeValue(value: number) {
    this.value = value;
  }
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
}
