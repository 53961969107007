import {Directive, ElementRef, inject, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[appIgnoreFieldsetDisabled]',
  standalone: true
})
export class IgnoreFieldsetDisabledDirective implements OnInit {
  @Input("appIgnoreFieldsetDisabled") appIgnoreFieldsetDisabled: boolean = false;
  private renderer = inject(Renderer2);

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    if (this.appIgnoreFieldsetDisabled) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', false);
      this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'false');
    }
  }
}
