import {NgModule} from '@angular/core';
import {AuthModule} from 'angular-auth-oidc-client';
import {environment} from "../../environments/environment";


@NgModule({
  imports: [AuthModule.forRoot({
    config: {
      secureRoutes: [environment.serverUrl, ''],
      authority: environment.oidcIssuerUrl,
      redirectUrl: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      clientId: environment.clientId,
      scope: 'openid profile email', // 'openid profile offline_access ' + your scopes
      responseType: 'code',
      silentRenew: true,
      useRefreshToken: true,
      autoUserInfo: true,
      renewUserInfoAfterTokenRenew: true,
      ignoreNonceAfterRefresh: true,
      renewTimeBeforeTokenExpiresInSeconds: 60,
      startCheckSession: true,
      triggerRefreshWhenIdTokenExpired: true,
      logLevel: environment.ngOidcLogLevel,
    }
  })],
  exports: [AuthModule],
})
export class AuthConfigModule {
}
