import {LogLevel} from "angular-auth-oidc-client";

export const environment = {
  production: false,
  serverUrl: 'https://smct.nhso.geniustree.io',
  oidcIssuerUrl: 'https://sso.nhso.geniustree.io/realms/nhso',
  clientId: 'smart-contract',
  ngOidcLogLevel: LogLevel.None,
  thaiDUrl: 'https://portal.nhso.geniustree.io/thaid/initial',
  portalUrl:'https://portal.nhso.geniustree.io',
  masterDataUrl:'https://master-data.nhso.geniustree.io'
};
