import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {LoginResponse, OidcSecurityService} from "angular-auth-oidc-client";
import {NavigationStart, Router} from "@angular/router";
import {ConfirmDialogService} from "./shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {distinctUntilChanged, Subscription} from "rxjs";
import {filter} from "rxjs/operators";
import {SecurityService} from "./security/security.service";

export let browserRefresh: boolean = false;

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Smart Contract';
  isLoginConfirmDialogOpen = false
  browserRefreshSubscription: Subscription;
  private securityAuthorityService = inject(SecurityService);
  private router = inject(Router);

  constructor(public oidcSecurityService: OidcSecurityService, router: Router, private confirmDialogService: ConfirmDialogService) {
    this.browserRefreshSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
  }

  ngOnInit() {
    this.oidcSecurityService
      .checkAuth()
      .pipe(
        distinctUntilChanged()
      )
      .subscribe((loginResponse: LoginResponse) => {
        const {isAuthenticated, userData, accessToken, idToken, configId} = loginResponse;
        // console.log(loginResponse)
        // this.userDataService.getCurrent()
        //   .subscribe(userData => {
        //     console.log(userData);
        //   });
        if (!isAuthenticated) {
          this.oidcSecurityService.authorize();
        }
        this.securityAuthorityService.hasAnyAuthority(['smart_contract'])
          .pipe(filter(e => !e))
          .subscribe(e => {
            this.router.navigate(['/403'])
          });
      });

    this.oidcSecurityService.checkSessionChanged$.subscribe(e => {
      console.log('checkSessionChanged$', e)
      if (e && !this.isLoginConfirmDialogOpen) {
        this.isLoginConfirmDialogOpen = true
        this.confirmDialogService.open({content: 'session หมดอายุ ต้องการล็อกอินอีกครั้บกด ยืนยัน?'}).subscribe(e => {
          if (e) {
            this.oidcSecurityService.logoff()
            this.oidcSecurityService.authorize()
          }
        })
      }
    })
  }

  ngOnDestroy(): void {
    if (this.browserRefreshSubscription && !this.browserRefreshSubscription.closed) {
      this.browserRefreshSubscription.unsubscribe()
    }
  }
}
