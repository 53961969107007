import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppSecurityModule} from "../security/app-security.module";
import {SharedCommonModule} from './commons/shared-common.module';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {ClipboardModule} from "ngx-clipboard";
import {DigitOnlyModule} from "@uiowa/digit-only";
import {CustomErrorComponent} from "./commons/custom-error.component";
import {SharedValidatorsModule} from "./validators/shared-validators.module";
import {NgbDropdown, NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import { SelectBudgetYearComponent } from './components/select-budget-year/select-budget-year.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppSecurityModule,
    NgSelectModule,
    NgbDropdownModule,
    ClipboardModule,
    DigitOnlyModule,
    SharedCommonModule,
    CustomErrorComponent,
    SharedValidatorsModule
  ],
  declarations: [
    SelectBudgetYearComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    SharedCommonModule,
    SelectBudgetYearComponent
  ],
  providers: [
    NgbDropdown
  ]
})
export class SharedModule {
}
