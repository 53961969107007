import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LayoutComponent} from "./pages/layout/layout.component";
import {SharedModule} from "./shared/shared.module";
import {AppSecurityModule} from "./security/app-security.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AbstractLoggerService, AuthInterceptor} from "angular-auth-oidc-client";
import {LogService} from "./auth/log.service";
import {FormsModule} from "@angular/forms";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AuthConfigModule} from "./auth/auth-config.module";
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    DashboardComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AuthConfigModule,
    AppSecurityModule,
    NgbModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: AbstractLoggerService, useClass: LogService}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
